import { Injectable } from "@angular/core";

export const Roles: any = {
  lol: [
    'top',
    'jungle',
    'middle',
    'bottom',
    'support',
    'fill'
  ],
  'wild-rift': [
    'top',
    'jungle',
    'middle',
    'bottom',
    'support',
    'fill'
  ],
  valorant: [
    'duelist',
    'controller',
    'initiator',
    'sentinel',
    'fill'
  ],
  apex: [
    'assault',
    'skirmisher',
    'recon',
    'support',
    'controller',
    'fill'
  ],
  marvel: [
    'duelist',
    'strategist',
    'vanguard',
    'fill'
  ]
};

@Injectable()
export class RolesService { }