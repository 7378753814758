<footer>
  <div class="footer-container">
    <div class="footer-copyrights">
      <picture class="device-decor device-decor-right">
        <img height="34" [lazyLoad]="ggLogo" alt="">
      </picture>

      <p class="footer-disclaimer">{{ page[lang].footerText }}</p>

      <p class="footer-copyright">© 2015-{{year}} ggboost.com | {{ page[lang].allRightsReserved }}</p>

      <!--<picture>
        <img height="30" [src]="googlePay" alt="">
        <img height="30" [src]="applePay" alt="">
        <img height="30" [src]="visa" alt="">
        <img height="30" [src]="mastercard" alt="">
        <img height="30" [src]="americanexpress" alt="">
      </picture>-->

      <div class="footer-links">
        <a aria-label="GGBoost Facebook" href="https://www.facebook.com/GGBoostOfficial" class="nav-link" target="_blank">
          <!-- <i class="ion-logo-facebook"></i> -->
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50"  fill="#9cb2c8">
            <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2 v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z"></path>
        </svg>
        </a>
        <a aria-label="GGBoost Instagram" href="https://instagram.com/ggboostofficial" class="nav-link" target="_blank" >
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50"  fill="#9cb2c8">
            <path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"></path>
            </svg>
        </a>
        <a aria-label="GGBoost Youtube" href="https://www.youtube.com/@ggboostcom" class="nav-link" target="_blank">
          <!-- <i class="ion-logo-youtube"></i> -->
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50" fill="#9cb2c8">
            <path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"></path>
            </svg>
        </a>
      </div>
    </div>
    <div class="footer-pages-wrapper">
      <div class="footer-pages">
        <div class="footer-section-title text-bold">LOL Boost</div>
        <ul class="list-unstyled">
          <li>
            <a href="{{ langUrlPrefix }}/lol-boost">Division Boost</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/lol-boost/placements">Placements</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/lol-boost/net-wins">Net Wins</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/lol-boost/games">Games</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/lol-boost/normal-wins">Normal Wins</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/lol-boost/leveling">Account Leveling</a>
          </li>
          <!-- <li>
            <a href="{{ langUrlPrefix }}/lol-boost/champion-mastery" >Champion Mastery</a>
          </li> -->
        </ul>
      </div>
      <div class="footer-pages">
        <div class="footer-section-title text-bold">Valorant Boost</div>
        <ul class="list-unstyled">
          <li>
            <a href="{{ langUrlPrefix }}/valorant-boost" >Rank Boost</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/valorant-boost/placements" >Placements</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/valorant-boost/net-wins" >Net Wins</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/valorant-boost/duo-games" >Duo Games</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/valorant-boost/unrated-wins" >Unrated Wins</a>
          </li>
        </ul>
      </div>
      <div class="footer-pages">
        <div class="footer-section-title text-bold">TFT Boost</div>
        <ul class="list-unstyled">
          <li>
            <a href="{{ langUrlPrefix }}/tft-boost" >Divisions Boost</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/tft-boost/placements" >Placements</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/tft-boost/net-wins" >Net Wins</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/tft-boost/normal-wins" >Normal Wins</a>
          </li>
        </ul>
      </div>
      <div class="footer-pages">
        <div class="footer-section-title text-bold">WR Boost</div>
        <ul class="list-unstyled">
          <li>
            <a href="{{ langUrlPrefix }}/wild-rift-boost" >Divisions Boost</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/wild-rift-boost/placements" >Placements</a>
          </li>
        </ul>
      </div>
      <div class="footer-pages">
        <div class="footer-section-title text-bold">Counter Strike 2</div>
        <ul class="list-unstyled">
          <li>
            <a href="{{ langUrlPrefix }}/cs2/premier-boosting">Premier Boost</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/cs2/placements-boosting">Placements</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/cs2/competitive-boosting">Competitive</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/cs2/premier-wins-boosting">Wins</a>
          </li>
        </ul>
      </div>
      <div class="footer-pages">
        <div class="footer-section-title text-bold">Apex Legends</div>
        <ul class="list-unstyled">
          <li>
            <a href="{{ langUrlPrefix }}/apex-legends/rank-boosting">Rank Boosting</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/apex-legends/badge-boosting">Badge Boosting</a>
          </li>
        </ul>
      </div>
      <div class="footer-pages">
        <div class="footer-section-title text-bold">Marvel Rivals</div>
        <ul class="list-unstyled">
          <li>
            <a href="{{ langUrlPrefix }}/marvel-rivals-boost">Rank Boosting</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/marvel-rivals-boost/quick-wins">Quick Wins</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/marvel-rivals-boost/net-wins">Net Wins</a>
          </li>
        </ul>
      </div>
      <div class="footer-pages">
        <div class="footer-section-title text-bold">{{ 'pages' | translate: lang }}</div>
        <ul class="list-unstyled">
          <li>
            <a href="{{ langUrlPrefix }}/faq">FAQ</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/reviews">{{ 'reviews' | translate: lang }}</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/booster-application">Booster Application</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/terms-of-use">{{ 'terms_use' | translate: lang }}</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/privacy-policy">{{ 'privacy_policy' | translate: lang }}</a>
          </li>
          <li>
            <a href="{{ langUrlPrefix }}/contact">{{ 'contact' | translate: lang }}</a>
          </li>
        </ul>
      </div>
    </div>

  </div>
</footer>