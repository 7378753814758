<header [class.static]="true">
  <div class="navigation-container">
    <nav class="navigation-bar">
      <a *ngIf="!isCustomer" [routerLink]="'/' + (lang === 'en' ? '' : lang) + returnUrl" aria-label="GGBoost logo image">
        <div class="gg-logo"></div>
      </a>
      <a *ngIf="isCustomer" [href]="baseUrl+'/'+ (lang === 'en' ? '' : lang) + returnUrl" aria-label="GGBoost logo image">
        <div class="gg-logo"></div>
      </a>

      <ul class="main-navigation">
        <li class="main-navigation__item --desktop-navigation-item">

          <div class="dropdown">
            <div class="dropdown-selected">
              <img width="20px" height="13px" src="https://cdn.ggboost.com/images/countries/{{selectedCountry.code !== 'en' ? selectedCountry.code : engFlag}}.webp" alt="{{ (selectedCountry.code + '_flag') | translate: lang }}"> {{ selectedCountry.name }} ▾
            </div>
            <div class="dropdown-menu">
              <ng-container *ngFor="let country of countries">
                <div class="dropdown-item"
                     (click)="selectCountry(country)"
                     *ngIf="country.code !== selectedCountry.code">
                  <img width="20px" height="13px" src="https://cdn.ggboost.com/images/countries/{{country.code !== 'en' ? country.code : engFlag}}.webp" alt="{{ (country.code + '_flag') | translate: lang }}"> {{ country.name }}
                </div>
              </ng-container>
            </div>
          </div>

        </li>
        <li class="main-navigation__item --desktop-navigation-item">
          <a *ngIf="!isCustomer" [routerLink]="getUrl('blog/all')" class="main-navigation__link">
            <app-button [condensed]="true" backgroundType="tertiary" colorType="light">{{ 'blog' | translate: lang }}</app-button>
          </a>
          <a *ngIf="isCustomer" [href]="getUrl('blog/all')" class="main-navigation__link">
            <app-button [condensed]="true" backgroundType="tertiary" colorType="light">{{ 'blog' | translate: lang }}</app-button>
          </a>
        </li>
        <li class="main-navigation__item --desktop-navigation-item">
          <a *ngIf="!isCustomer" [routerLink]="getUrl('reviews')" class="main-navigation__link">
            <app-button [condensed]="true" backgroundType="tertiary" colorType="light">{{ 'reviews' | translate: lang }}</app-button>
          </a>
          <a *ngIf="isCustomer" [href]="getUrl('reviews')" class="main-navigation__link">
            <app-button [condensed]="true" backgroundType="tertiary" colorType="light">{{ 'reviews' | translate: lang }}</app-button>
          </a>
        </li>
        <li class="main-navigation__item --desktop-navigation-item">
          <a *ngIf="!isCustomer" [routerLink]="getUrl('tutorial')" class="main-navigation__link">
            <app-button [condensed]="true" backgroundType="tertiary" colorType="light">{{ 'tutorial' | translate: lang }}</app-button>
          </a>
          <a *ngIf="isCustomer" [href]="getUrl('tutorial')" class="main-navigation__link">
            <app-button [condensed]="true" backgroundType="tertiary" colorType="light">{{ 'tutorial' | translate: lang }}</app-button>
          </a>
        </li>
        <li class="main-navigation__item --medium-navigation-item">
            <a href="#" (click)="openAccount($event);">
              <app-button
                backgroundType="primary"
                colorType="light"
                hoverBackgroundType="light"
                hoverColorType="primary"
              >
              {{ 'my_account' | translate: lang }}
              </app-button>
            </a>
        </li>
        <li class="main-navigation__item --medium-navigation-item">
          <a *ngIf="!isCustomer" class="main-navigation__link" [routerLink]="getUrl('boosting-services')">
            <app-button  backgroundType="danger" colorType="light" hoverBackgroundType="light" hoverColorType="danger">
              {{ 'our_games' | translate: lang }}
            </app-button>
          </a>
          <a *ngIf="isCustomer" class="main-navigation__link" [href]="getUrl('boosting-services')">
            <app-button [margin]="false" backgroundType="danger" colorType="light" hoverBackgroundType="light" hoverColorType="danger">
              {{ 'our_games' | translate: lang }}
            </app-button>
          </a>
        </li>
        <li class="main-navigation__item">
          <div class="mobile-navigation-btn" [class.open]="isMobileNavOpen" (click)="toggleMobileNav(!isMobileNavOpen)">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</header>

<div class="mobile-navigation-wrapper" [class.open]="isMobileNavOpen">
  <div class="mobile-nav-scroller">
    <ul class="mobile-navigation">
      <li (click)="openAccount($event)" class="mobile-navigation__item">
        {{ 'my_account' | translate: lang }}
      </li>

      <li class="mobile-navigation__item">
        <a *ngIf="!isCustomer" class="main-navigation__link" [routerLink]="getUrl('boosting-services')">
          {{ 'our_games' | translate: lang }}
        </a>
        <a *ngIf="isCustomer" class="main-navigation__link" [href]="getUrl('boosting-services')">
          {{ 'our_games' | translate: lang }}
        </a>
      </li>

      <li (click)="goTo($event, getUrl('reviews'))" class="mobile-navigation__item">
        <a *ngIf="!isCustomer" [routerLink]="getUrl('reviews')" class="main-navigation__link">
          {{ 'reviews' | translate: lang }}
        </a>
        <a *ngIf="isCustomer" [href]="getUrl('reviews')" class="main-navigation__link">
          {{ 'reviews' | translate: lang }}
        </a>
      </li>
      <li (click)="goTo($event, getUrl('tutorial'))" class="mobile-navigation__item">
        <a *ngIf="!isCustomer" [routerLink]="getUrl('tutorial')" class="main-navigation__link">
          {{ 'tutorial' | translate: lang }}
        </a>
        <a *ngIf="isCustomer" [href]="getUrl('tutorial')" class="main-navigation__link">
          {{ 'tutorial' | translate: lang }}
        </a>
      </li>
      <li (click)="goTo($event, getUrl('blog/all'))" class="mobile-navigation__item">
        <a *ngIf="!isCustomer" [routerLink]="getUrl('blog/all')" class="main-navigation__link">
          {{ 'blog' | translate: lang }}
        </a>
        <a *ngIf="isCustomer" [href]="getUrl('blog/all')" class="main-navigation__link">
          {{ 'blog' | translate: lang }}
        </a>
      </li>
      <li class="mobile-available-langs">
        <ng-container *ngFor="let country of countries">
          <div class="mobile-lang"
              (click)="selectCountry(country)"
              *ngIf="country.code !== selectedCountry.code">
            <img width="20px" height="13px" src="https://cdn.ggboost.com/images/countries/{{country.code !== 'en' ? country.code : engFlag}}.webp" alt="{{ (country.code + '_flag') | translate: lang }}"> {{ country.name }}
          </div>
        </ng-container>
      </li>
    </ul>
  </div>
</div>