import {NgModule} from '@angular/core';
import {RoleGuard} from './guards/role.guard';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {UserService} from './services/user.service';
import {CookieService} from 'ngx-cookie-service';
import {ApiInterceptor} from './interceptors/api.interceptor';
import {CommonModule} from '@angular/common';
import {DivisionsService} from './services/divisions.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PriceService} from './services/price.service';
import { RolesService } from './services/roles.service';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule
  ],
  providers: [
    UserService,
    RoleGuard,
    CookieService,
    DivisionsService,
    PriceService,
    RolesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
  ],
  exports: [
    CommonModule,
    HttpClientModule
  ]
})
export class CoreModule {
}
